var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", { staticClass: "text-center" }, [_vm._v(_vm._s(_vm.title))]),
      _vm.errorMessage !== ""
        ? _c("div", [_vm._v(_vm._s(_vm.errorMessage))])
        : !_vm.isResetPasswordSent
        ? _c(
            "app-form",
            {
              attrs: {
                id: "password-reset-form",
                submitAction: _vm.sendResetPassword,
                submitSuccessAction: () => (_vm.isResetPasswordSent = true),
                validationOptions: _vm.validationOptions
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-textbox", {
                    attrs: {
                      id: "email",
                      placeholder: _vm.$t("account.email"),
                      type: "email"
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  })
                ],
                1
              ),
              _c("app-form-buttons", {
                staticClass: "login",
                attrs: {
                  addSubmitButton: true,
                  "add-cancel-button": true,
                  submitButtonText: "account.resetPasswordRequest.submit",
                  cancelButtonText:
                    "account.resetPasswordRequest.returnToLogin",
                  cancelAction: _vm.returnToLogin
                }
              })
            ],
            1
          )
        : _c("div", [
            _c("div", [
              _vm._v(_vm._s(_vm.$t("account.resetPasswordRequest.success")))
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }